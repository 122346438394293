#navbar {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  height: 72px;
  z-index: 999;
  border-bottom: 1px solid #e0e0e0;
}
#navbar.shadow {
  box-shadow: -1px -8px 17px 0px rgba(0, 0, 0, 0.329);
}

#navbar .user-info {
  float: right;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

#navbar .user-info .avatar-user .conatiner-name-merchant {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f4f6;
  padding: 5px 5px 5px 10px;
  border-radius: 30px;
  cursor: pointer;
  transition: height 250ms ease-out, background 250ms ease-out;
}

#navbar .user-info .avatar-user .conatiner-name-merchant .name {
  margin-right: 10px;
}

#navbar .user-info .avatar-user .conatiner-name-merchant .letters {
  background-color: #000;
  color: #fff;
  padding: 15px;
  border-radius: 30px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}
#navbar .user-info .avatar-user .options-menu {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  right: 13px;
  bottom: -58px;
  transition: width 250ms ease-out;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0px #0000003d;
  display: none;
  padding: 10px;
  border-radius: 10px;
}

#navbar .user-info .avatar-user .options-menu.show {
  display: block;
}

/*
** Mobile Version
*/

@media screen and (max-width: 600px) {
  #navbar .user-info .avatar-user .conatiner-name-merchant {
    background: #fff;
    padding: 0;
  }

  #navbar .user-info .avatar-user .conatiner-name-merchant .name {
    display: none;
  }
}
