.wrap-credentials .container-categories {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.wrap-credentials .container-categories .production,
.wrap-credentials .container-categories .test {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  min-height: 70vh;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.wrap-credentials .container-categories .production .title-prod,
.wrap-credentials .container-categories .test .title-test {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  position: relative;
  width: 100%;
  margin-bottom: 32px;
  height: 36px;
}

.wrap-credentials .container-categories .production .title-prod .length,
.wrap-credentials .container-categories .test .title-test .length {
  position: absolute;
  right: 0;
  font-size: 14px;
  font-weight: 500;
}

.wrap-credentials .container-categories .test .message {
  font-size: 12px;
  margin-bottom: 25px;
}

.wrap-credentials .container-categories .production .icon-prod,
.wrap-credentials .container-categories .test .icon-test {
  margin-right: 20px;
}

.wrap-credentials .container-categories .production .icon-prod {
  width: 36px;
  height: 36px;
  font-size: 36px;
}

.wrap-credentials .container-categories .test .icon-test {
  width: 21px;
}

.wrap-credentials .container-categories .region-information {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: auto;
}

.wrap-credentials .container-categories .region-information .container-empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: auto;
}

.wrap-credentials .container-categories .region-information .container-empty-data .message-empty {
  margin-top: 20px;
  font-size: 14px;
}

.wrap-credentials .container-categories .container-link {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 10px 30px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  margin-bottom: 13px;
}

.wrap-credentials .container-categories .container-link:last-child {
  margin-bottom: 25px;
}

.wrap-credentials .container-categories .container-link .icon-credential {
  margin-right: 10px;
  margin-top: 5px;
}

.wrap-credentials .container-categories .container-link .title-credential {
  flex: 1;
  line-height: 20px;
  font-size: 16px;
  margin: 0;
  padding-right: 25px;
}

.wrap-credentials .container-categories .container-link .title-credential p {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: #63666a;
}

.wrap-credentials .container-categories .container-link .icon-arrow {
  right: 25px;
  top: calc(50% - 12px);
  position: absolute;
}

.wrap-credentials .container-categories .cta {
  border-radius: 10px;
  box-shadow: none;
  font-size: 16px;
  height: 48px;
}

.wrap-credentials .container-categories .message-max-length {
  max-width: 177px;
  font-size: 11px;
  color: #63666a;
}

/*
** Mobile Version
*/

@media screen and (max-width: 600px) {
  .wrap-credentials .container-categories {
    display: flex;
    flex-direction: column;
  }

  .wrap-credentials .container-categories .production,
  .wrap-credentials .container-categories .test {
    min-height: auto;
  }

  .wrap-credentials .container-categories .production .title-prod,
  .wrap-credentials .container-categories .test .title-test {
    margin-bottom: 22px;
  }

  .wrap-credentials .container-categories .region-information {
    padding: 15px 0;
  }
}
