:root {
  /*
  ** Medias Default breakpoints
  ** https://mui.com/material-ui/customization/breakpoints/ 
  */
  --xs-breakpoint: 0;
  --sm-breakpoint: 600px;
  --md-breakpoint: 900px;
  --lg-breakpoint: 1200px;
  --xl-breakpoint: 1536px;
}
