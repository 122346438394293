.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.iframe {
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
}

.iframe.notLoaded {
  visibility: hidden;
  width: 0;
  height: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'clip';
  src: url('fonts/clip.eot?w047pn');
  src: url('fonts/clip.eot?w047pn#iefix') format('embedded-opentype'), url('fonts/clip.ttf?w047pn') format('truetype'),
    url('fonts/clip.woff?w047pn') format('woff'), url('fonts/clip.svg?w047pn#clip') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='clip-icon-'],
[class*=' clip-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'clip', serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clip-icon-clip:before {
  content: '\e943';
  color: #66737b;
}
.clip-icon-code:before {
  content: '\e93f';
}
.clip-icon-minutes:before {
  content: '\e900';
}
.clip-icon-account:before {
  content: '\e901';
}
.clip-icon-add:before {
  content: '\e902';
}
.clip-icon-bank:before {
  content: '\e903';
}
.clip-icon-bin:before {
  content: '\e904';
}
.clip-icon-bluetooth:before {
  content: '\e905';
}
.clip-icon-calculator:before {
  content: '\e906';
}
.clip-icon-calender:before {
  content: '\e907';
}
.clip-icon-clean:before {
  content: '\e908';
}
.clip-icon-commissions:before {
  content: '\e909';
}
.clip-icon-compatibility:before {
  content: '\e90a';
}
.clip-icon-contactless:before {
  content: '\e90b';
}
.clip-icon-copy:before {
  content: '\e90c';
}
.clip-icon-credit-card:before {
  content: '\e90d';
}
.clip-icon-delivering:before {
  content: '\e90e';
}
.clip-icon-documents:before {
  content: '\e90f';
}
.clip-icon-done:before {
  content: '\e910';
}
.clip-icon-download:before {
  content: '\e911';
}
.clip-icon-edit:before {
  content: '\e912';
}
.clip-icon-email:before {
  content: '\e913';
}
.clip-icon-fast:before {
  content: '\e914';
}
.clip-icon-file:before {
  content: '\e915';
}
.clip-icon-filters:before {
  content: '\e916';
}
.clip-icon-flag:before {
  content: '\e917';
}
.clip-icon-gift:before {
  content: '\e918';
}
.clip-icon-heart:before {
  content: '\e919';
}
.clip-icon-help_outline:before {
  content: '\e91a';
}
.clip-icon-launch:before {
  content: '\e91b';
}
.clip-icon-list:before {
  content: '\e91c';
}
.clip-icon-manual:before {
  content: '\e91d';
}
.clip-icon-menu-rounded:before {
  content: '\e91e';
}
.clip-icon-menu:before {
  content: '\e91f';
}
.clip-icon-money:before {
  content: '\e920';
}
.clip-icon-more:before {
  content: '\e921';
}
.clip-icon-nop:before {
  content: '\e922';
}
.clip-icon-box:before {
  content: '\e923';
}
.clip-icon-pay:before {
  content: '\e924';
}
.clip-icon-percentaje:before {
  content: '\e925';
}
.clip-icon-Photo:before {
  content: '\e927';
}
.clip-icon-play-circle:before {
  content: '\e928';
}
.clip-icon-product:before {
  content: '\e929';
}
.clip-icon-receive:before {
  content: '\e92a';
}
.clip-icon-refund .path1:before {
  content: '\e92b';
  color: rgb(0, 0, 0);
}
.clip-icon-refund .path2:before {
  content: '\e941';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.clip-icon-refund .path3:before {
  content: '\e942';
  margin-left: -1em;
  color: rgb(0, 23, 36);
}
.clip-icon-reload:before {
  content: '\e92d';
}
.clip-icon-reviews:before {
  content: '\e92e';
}
.clip-icon-save:before {
  content: '\e92f';
}
.clip-icon-search:before {
  content: '\e930';
}
.clip-icon-secure:before {
  content: '\e931';
}
.clip-icon-send-money:before {
  content: '\e932';
}
.clip-icon-share:before {
  content: '\e933';
}
.clip-icon-shop:before {
  content: '\e934';
}
.clip-icon-stickers:before {
  content: '\e935';
}
.clip-icon-store:before {
  content: '\e936';
}
.clip-icon-transfer:before {
  content: '\e937';
}
.clip-icon-trophy:before {
  content: '\e938';
}
.clip-icon-unlocked:before {
  content: '\e939';
}
.clip-icon-upload-file:before {
  content: '\e93a';
}
.clip-icon-reader:before {
  content: '\e93b';
}
.clip-icon-eye:before {
  content: '\e93c';
}
.clip-icon-watch:before {
  content: '\e93d';
}
.clip-icon-web:before {
  content: '\e93e';
}
