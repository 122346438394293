.wrap-skeleton .skeleton {
  animation: skeleton-loading 400ms linear infinite alternate;
  min-height: 14px;
  width: 100%;
  border-radius: 12px;
}
.wrap-skeleton .skeleton:not(:last-child) {
  margin-bottom: 1em;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 90%);
  }
  100% {
    background-color: hsl(0, 0%, 95%);
  }
}
