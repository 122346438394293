.wrap-detail-credential {
  width: 60%;
}

.wrap-detail-credential .region-form {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}

.wrap-detail-credential .region-form > *:not(:last-child) {
  margin-bottom: 20px;
}

.wrap-detail-credential .region-form .container-checkbox > span.crc-MuiTypography-root {
  font-size: 14px;
}

.wrap-detail-credential .region-form .region-copy {
  position: relative;
}

.wrap-detail-credential .message {
  display: flex;
  background-color: #f3f4f6;
  padding: 15px;
  border-radius: 16px;
  align-items: center;
}

.wrap-detail-credential .message .icon-info {
  margin-right: 15px;
}

.wrap-detail-credential .message.warning {
  background-color: #fdf2dc;
}

.wrap-detail-credential .message.warning .icon-info {
  color: #ce8400;
}

.wrap-detail-credential .title span {
  font-size: 11px;
  padding: 5px 8px;
  color: #fff;
  border-radius: 100px;
  margin-left: 10px;
  bottom: 5px;
  position: relative;
}

.wrap-detail-credential .title span.label-prod {
  background-color: #1d865b;
}
.wrap-detail-credential .title span.label-test {
  background-color: #005cd3;
}

.wrap-detail-credential .title .icon-delete {
  width: 16px;
  height: 18px;
  float: right;
  margin-top: 16px;
}

.wrap-detail-credential .title .icon-delete,
.wrap-detail-credential .icon-copy {
  cursor: pointer;
}

.container-action-delete-modal .description {
  padding: 0 65px 0 25px;
  margin: 15px 0;
}

.container-action-delete-modal .region-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 15px 0;
  border-top: 1px solid #e0e0e0;
  margin-top: 20px;
}

.container-action-delete-modal .region-actions .cancelar {
  font-size: 16px;
  color: #353536;
  font-weight: 600;
  margin-right: 30px;
  padding: 10px;
  cursor: pointer !important;
}

.container-action-delete-modal .region-actions .delete:disabled {
  color: #fff;
  background-color: #fc4c02;
}

/*
** Mobile Version
*/

@media screen and (max-width: 600px) {
  .wrap-detail-credential {
    width: 90%;
    padding-bottom: 20px;
  }
}
