.wrap-loading {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrap-loading .circular-progress {
  margin-bottom: 20px;
}
.wrap-loading .legend {
  font-weight: 600;
}
