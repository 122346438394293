.sidebar-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar-list .container-link {
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  border-radius: 20px;
  text-wrap: nowrap;
  overflow-x: hidden;
  color: inherit;
  text-decoration: none;
}

.sidebar-list .container-link:hover {
  background-color: rgba(37, 40, 42, 0.08);
}

.sidebar-list > *:not(:last-child),
.sidebar-list .wrap-toggle .items-toggle > *:not(:last-child),
.wrap-toggle .popover.show .container-children > *:not(:last-child) {
  margin-bottom: 12px;
}

.sidebar-list .container-link .icon-link {
  height: 20px;
  width: 20px;
  padding: 8px 0 8px 8px;
  box-sizing: content-box;
  font-size: 22px;
}

.sidebar-list .container-link .icon-link.icon-clip {
  font-size: 20px;
}

.sidebar-list .container-link .text-link {
  margin-left: 15px;
  box-sizing: content-box;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
}

.sidebar-list .container-link.active p {
  color: #ca3e02;
}

.sidebar-list .container-link.active {
  background-color: #fcf1ed;
  color: #ca3e02;
}

.sidebar-list .container-link.active img {
  filter: invert(27%) sepia(81%) saturate(5189%) hue-rotate(21deg) brightness(96%) contrast(98%);
}

.sidebar-list .container-link.active .text-link {
  font-weight: 700;
}

.sidebar-list .wrap-toggle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 36px;
  position: relative;
}

.sidebar-list .wrap-toggle.collapsed {
  overflow-x: hidden;
  cursor: pointer;
}

.sidebar-list .wrap-toggle.collapsed:hover {
  overflow-x: hidden;
  cursor: pointer;
  background-color: rgba(37, 40, 42, 0.08);
  border-radius: 20px;
}

.sidebar-list .wrap-toggle .category-name {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.sidebar-list .wrap-toggle .category-name.active {
  border-radius: 20px;
  background-color: #fcf1ed;
}

.sidebar-list .wrap-toggle .category-name.active > img {
  filter: invert(27%) sepia(81%) saturate(5189%) hue-rotate(21deg) brightness(96%) contrast(98%);
}

.sidebar-list .wrap-toggle .category-name .icon-link {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  padding: 8px;
  box-sizing: content-box;
}

.sidebar-list .wrap-toggle .category-name .icon-arrow {
  position: absolute;
  right: 0;
}

.sidebar-list .wrap-toggle .items-toggle {
  width: 100%;
  overflow: hidden;
  transition: height 250ms ease-in, padding 150ms ease-in 50ms;
  padding: 10px 0;
  box-sizing: content-box;
}

.sidebar-list .wrap-toggle .items-toggle.hidden {
  height: 0 !important;
  padding: 0;
}

.sideBar > div {
  width: 80px;
  position: sticky;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  text-wrap: nowrap;
  flex-direction: column;
  padding: 20px 21px;
  transition: width 250ms ease-out, padding 250ms ease-out;
}

.sideBar > div .sidebar-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: width 250ms ease-out;
  margin-bottom: 30px;
}

.sideBar > div .sidebar-head .logo-clip {
  width: 32px;
  height: 32px;
  transition: width 250ms ease-out, height 250ms ease-out;
}

.sideBar > div .sidebar-head .logo-clip.hidden {
  width: 0;
}

.sideBar > div .sidebar-head .icon-menu {
  height: 24px;
  cursor: pointer;
  box-sizing: content-box;
  padding: 7px;
  width: 24px;
}

.sideBar.openSideBar > div {
  width: 255px;
  padding: 20px 30px;
}

.sideBar.openSideBar > div .sidebar-head {
  width: 100%;
  justify-content: space-between;
}

.wrap-toggle .popover {
  position: fixed;
  left: 81px;
  height: 0;
  width: 0;
}

.wrap-toggle .popover.show {
  width: auto;
  height: auto;
  box-shadow: 4px 5px 11px 0px rgba(0, 0, 0, 0.18);
  border-radius: 15px;
}

.wrap-toggle .popover.show::before {
  content: '';
  width: 15px;
  height: 15px;
  background: #ffffff;
  position: absolute;
  left: -5px;
  z-index: -1;
  top: 10px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.18);
  transform: rotate(45deg);
}

.wrap-toggle .popover.show .container-children {
  background: white;
  padding: 10px;
  z-index: 9999;
  border-radius: 15px;
}

.wrap-toggle .popover.show .container-children > * {
  padding-right: 15px;
}

.wrap-toggle .popover .background-popover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: -1;
  cursor: auto;
  display: none;
}

.wrap-toggle .popover.show .background-popover {
  display: block;
  visibility: hidden;
}

/* 
 * MOBIL VERSION ------------------------------
*/
.sideBar.mobil > div {
  position: fixed;
  width: 0;
  padding: 20px 0;
  overflow: visible;
  z-index: 99999;
}

.sideBar.mobil > div .sidebar-head {
  min-height: 48px;
}

.sideBar.mobil > div .sidebar-head .icon-menu {
  margin-left: 15px;
  margin-top: -25px;
}

.sideBar.mobil.openSideBar > div .sidebar-head .icon-menu {
  margin-left: 0;
  margin-top: 0;
}

.sideBar.mobil.openSideBar > div {
  width: 100vw;
  padding: 20px 30px;
  overflow: hidden;
  z-index: 999999;
}
