.wrap-detail-credential-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 10px 10px;
}
.wrap-detail-credential-view .region-detail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-detail-credential-view .btn-back:hover {
  background-color: transparent;
}

.container-action-confirm-modal {
  max-width: 500px;
}
.container-action-confirm-modal .description {
  padding: 0 70px 0 25px;
}
.container-action-confirm-modal .region-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 15px 0;
  border-top: 1px solid #e0e0e0;
  margin-top: 20px;
}
.container-action-confirm-modal .region-actions .cancelar {
  font-size: 16px;
  color: #353536;
  font-weight: 600;
  margin-right: 30px;
  padding: 10px;
  cursor: pointer !important;
}

/*
** Mobile Version
*/

@media screen and (max-width: 600px) {
  .wrap-detail-credential-view .btn-back {
    min-width: 70px !important;
  }
}
