.container-action-create-credential .region-data,
.container-action-create-credential .region-actions {
  padding: 0 24px;
}

.container-action-create-credential .region-data .input-name-credetial {
  width: 100%;
  margin: 15px 0;
}

.container-action-create-credential .region-data .input-name-credetial .icon-error {
  color: #b91600;
  transform: rotate(180deg) !important;
}

.container-action-create-credential .region-data .rules {
  font-size: 11px;
  color: #5e5f73;
  display: flex;
  flex-wrap: wrap;
  padding-inline-start: 15px !important;
  list-style: none;
}

.container-action-create-credential .region-data .rules .item-rule {
  color: #5e5f73;
  position: relative;
}

.container-action-create-credential .region-data .rules .item-rule::before {
  content: '';
  background-color: #5e5f73;
  position: absolute;
  width: 2.5px;
  height: 2.5px;
  left: -10px;
  top: 42%;
  border-radius: 20px;
}

.container-action-create-credential .region-data .rules .item-rule:not(:last-child) {
  margin-right: 35px;
}

.container-action-create-credential .region-data .rules .item-rule.success {
  color: #177850;
}
.container-action-create-credential .region-data .rules .item-rule.success::before {
  content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.666748 5.66667L4.00008 9L11.3334 1' stroke='%232D9F70' stroke-width='1.25097' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  width: 0;
  height: 0;
  top: 1px;
  left: -15px;
}

.container-action-create-credential .region-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
}

.container-action-create-credential .region-actions .cancelar {
  font-size: 16px;
  color: #353536;
  font-weight: 600;
  margin-right: 30px;
  padding: 10px;
  cursor: pointer !important;
}

.container-action-create-credential .region-actions .create:disabled {
  color: #fff;
  background-color: #fc4c02;
}
