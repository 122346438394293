.wrap-alert .container-snackbar .crc-MuiAlert-root {
  min-width: 400px;
}

.wrap-alert .container-snackbar .crc-MuiAlert-root {
  border-radius: 10px;
  min-height: 48px;
}

.wrap-alert .container-snackbar .crc-MuiAlert-container {
  border: none;
  display: flex;
  align-items: center;
}

.wrap-alert .container-snackbar .crc-MuiAlert-borderLeft {
  display: none;
}

.wrap-alert .container-snackbar .crc-MuiAlert-clipSuccess {
  background-color: #25282a;
}

.wrap-alert .container-snackbar .crc-MuiAlert-clipSuccess .crc-MuiAlert-container .crc-MuiAlert-icon {
  margin-right: 15px;
}

.wrap-alert .container-snackbar .crc-MuiAlert-clipError {
  background-color: #e51c01;
}

/*
** Mobile Version
*/

@media screen and (max-width: 600px) {
  .wrap-alert .container-snackbar .crc-MuiAlert-root {
    min-width: 100%;
  }
}
