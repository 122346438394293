/*
** overwrite styles components MUI
*/

div.crc-MuiInputBase-root {
  border-radius: 9px;
}

div.Mui-focused fieldset {
  border: 1px solid rgb(51, 69, 79) !important;
}
label.Mui-focused {
  color: rgb(51, 69, 79) !important;
}

.crc-MuiDialogContent-root {
  margin: 0 0 16px 0 !important;
}

button.crc-MuiButtonBase-root {
  box-shadow: none !important;
  border-radius: 10px !important;
  width: fit-content !important;
  height: 48px;
  min-width: fit-content !important;
}

/*
** Mobile Version
*/

@media screen and (max-width: 600px) {
  .crc-MuiDialog-root {
    z-index: 99999999 !important;
  }

  .crc-MuiDialog-container {
    align-items: flex-end !important;
  }
  .crc-MuiDialog-container .crc-MuiDialog-paper {
    margin: 0;
    width: 100%;
    max-width: 100% !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
